import React from "react"
import Container from "../components/container/container";
import SEO from "../components/seo";
import YM from "../components/analytics/ym";
import Laminat from "../components/articles/laminat";

const LaminatPage = () => (
    <Container>
        <SEO
            title="complexstroy.by | Как подобрать цвет ламината к интерьеру"
            description="Как подобрать цвет ламината к интерьеру"/>
        <YM />
        <Laminat />
    </Container>
);

export default LaminatPage
