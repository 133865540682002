import React from "react"
import styles from "./article-pages.module.css"
import {Link} from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import LinkButton from "../buttons/link-button/link-button";
import Background from "../../../static/articles/laminat/bg.jpg";

const Laminat = () => {

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <div className={modal ? styles.articleModal : styles.articlePage}>
                    {modal ? (
                        <Link className={"close-modal"} to={"/#articles"}>&times;</Link>
                    ) : "" }
                    <div className={styles.articleBackground} style={{
                        backgroundImage: `url(${Background})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <div className={styles.imageBackground}>
                            <p className={styles.backgroundText}>
                                Как подобрать цвет ламината к интерьеру
                            </p>
                        </div>
                    </div>
                    <div className={styles.content}>

                        <p>Цвет пола определяет как будет смотреться интерьер в целом. Конечно, если покрытие
                            выбрано неправильно, его можно перестелить, но тогда придется заново тратить время и
                            деньги. Поэтому лучше выбирать осмысленно: так, чтобы потом жалеть не пришлось. Тем
                            более, что хороший ламинат долговечен и без проблем переживает последующие ремонты.
                            Вы несколько раз переклеите обои и поменяете мебель, а пол останется тем же.
                            Отлично, если при этом интерьер сохранит свою целостность<br/>
                        </p>

                        <h2>На какие характеристики стоит обратить внимание при покупке?</h2>

                        <p>Когда покупатель выбирает ламинат, он смотрит на цену, внешний вид, а иногда — на
                            класс износостойкости. Последняя характеристика перестала быть важной, когда
                            технологии производства модернизировались, а рынок пополнился китайскими брендами.
                            Например, покрытие 34 класса из Китая может уступать 31 классу из Германии. Лучше
                            ориентироваться на гарантию: если производитель уверен в качестве, он укажет
                            длительный гарантийный срок. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/1.jpg"/>
                        </div>

                        <p>Все технические характеристики указываются на упаковке. Рассмотрим, какие маркировки
                            важны, если планируете уложить ламинат в жилом помещении:<br/></p>

                        <ul>

                            <li>толщина панели: достаточно 8-9 мм. Если этот показатель выше, скорее всего,
                                производитель использовал материал небольшой плотности. Такая ламель может
                                прогнуться под воздействием статичных нагрузок;
                            </li>

                            <li>истираемость: для коридора или столовой нормальный показатель — AC3 (W3). Для
                                спальни или детской он может быть меньше;
                            </li>

                            <li>влагоустойчивость: обязательна для кухни;</li>

                            <li>плотность: от 850 кг/м<sup>3</sup>.</li>

                        </ul>

                        <p>Иногда на упаковку помещают значки, которые тоже дают информацию о продукте. Это
                            удобно: характеристики наглядны, и понять, подходит ли покрытие, проще. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/2.jpg"/>
                        </div>

                        <ul>

                            <li>изображение обуви на каблуке — знак того, что ламинат устойчив к царапинам, и по
                                нему можно передвигать мебель.
                            </li>

                            <li>если видите молнию — покрытие антистатично, не притягивает пыль.</li>

                            <li>картинка с солнечными лучами означает, что декоративный слой не потеряет своей
                                яркости из-за ультрафиолета.
                            </li>

                        </ul>

                        <h2>Цветовая гамма ламината</h2>

                        <p>Цветовая гамма ламината определяет, будет ли интерьер гармоничным. Дизайнеры советуют
                            не сочетать больше трех основных цветов. Если стены нейтральные, пол может быть
                            насыщенным, а расставить акценты помогут текстиль и мебель. И наоборот: если обои с
                            крупным орнаментом, нужно приглушенное напольное покрытие. </p>

                        <p>Общее правило: для помещения, оформленного в холодных тонах, подойдет такой же
                            «холодный» ламинат. И наоборот — теплые оттенки требуют себе в поддержку теплых
                            цветов. На рисунке показано, как работать с цветовой температурой. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/3.jpg"/>
                        </div>

                        <p>Советы, которые помогут соблюсти гармонию в цвете:<br/></p>

                        <ul>

                            <li>сосна, береза, дуб и бук считаются нейтральными решениями. Они подходят для
                                любой комнаты, но особых впечатлений такой интерьер не произведет. Чтобы сделать
                                его интереснее, расставляйте акценты;
                            </li>

                            <li>рыжий оттенок, характерный для ольхи, яблони, вишни и груши, сочетается с
                                желтыми и зелеными тонами. Отлично, если мебель сделана из натуральной
                                древесины, а на стенах или текстиле присутствует растительный орнамент;
                            </li>

                            <li>если придерживаетесь лаконичного стиля, используйте светлые породы дерева —
                                клен, выбеленный дуб. Они подойдут и для создания контрастов. Главное — помнить
                                о правиле трех цветов;
                            </li>

                            <li>самый сложный для дизайнера ламинат — темного оттенка (венге). Он утяжеляет
                                нижнюю часть помещения и категорически не подходит для маленьких комнат. Чтобы
                                сделать интерьер более легким, добавьте ярких красок, которые перетянут внимание
                                на себя;
                            </li>

                            <li>сложно работать и с серым цветом. Он прекрасно подходит для интерьеров в стиле
                                хай-тек, но в поддержку требует мебели нейтрального оттенка, металлических
                                деталей или сложной игры на черно-белом контрасте;
                            </li>

                            <li>чтобы разбить комнату на зоны, комбинируйте разные цвета ламината. Используйте
                                только родственные породы дерева (например, выбеленный дуб с обычным) и
                                однородные тона (холодные с холодными).
                            </li>

                        </ul>

                        <h2>Как подбираются обои под ламинат?</h2>

                        <p>Рассказываем, как правильно подобрать обои, если пол уже уложен. Какие выбрать —
                            зависит от цвета напольного покрытия. </p>

                        <p><strong>Красный оттенок ламината. </strong>Миланский орех и вишня дают благородный
                            красноватый отблеск. который ценят любители классики. Сочетать такой пол со стенами
                            несложно: для спальни или гостиной выбирайте плотные флизелиновые обои светлых тонов
                            с красными цветами. Если нужно оформить детскую, подойдут нежно-голубые или
                            болотно-зеленые варианты. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/4.jpg"/>
                        </div>

                        <p><strong>Коричневый. </strong>Это самый распространенный вид напольного покрытия,
                            которые сочетается едва ли не с любыми стенами. Кремовый, желтый, зеленый, все
                            модификации пастельных тонов — решайте сами. Добавить индивидуальности помогут
                            картины на стенах — пусть в них тоже преобладают коричневые тона.<br/></p>

                        <p>Интересное решение — комбинировать темно-коричневый и фиолетовый. Смелое сочетание
                            подойдет только для просторных комнат, в которых много света. Продумайте и
                            дополнительное освещение в вечернее время: добавьте светодиодных светильников,
                            которые создадут ощущение уюта. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/5.jpg"/>
                        </div>

                        <p><strong>Серый. </strong>Еще один распространенный вариант, который выбирают из-за его
                            непритязательности — пыль на таком покрытии почти не видна. Сочетайте его с обоями
                            такого же оттенка любой яркости — от насыщенного до почти белого. Чтобы расставить
                            акценты, выбирайте желтые аксессуары. Осторожнее с красным: этот энергичный цвет
                            нужно разбавлять бежевыми элементами.<br/></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/6.jpg"/>
                        </div>

                        <p><strong>Светлый. </strong>Такой пол зрительно расширяет комнату, а комбинировать его
                            с обоями проще всего. Избегайте только насыщенных фиолетовых оттенков: они сведут на
                            нет все попытки расширить пространство. Хорошо смотрятся пастельный пол с яркими
                            стенами, но тогда мебель должна быть нейтральной.<br/></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/7.jpg"/>
                        </div>

                        <p><strong>Темный.</strong> Обыграть венге сложно. Мы не советуем проводить эксперименты
                            с цветом: без помощи профессионального дизайнера они, скорее всего, провалятся.
                            Выбирайте классические светлые обои и старайтесь дать как можно больше света. Это
                            поможет добиться гармонии.<br/></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/8.jpg"/>
                        </div>

                        <h2>Как подобрать ламинат к дверям?</h2>

                        <p>Здесь ситуация обратная: двери уже установлены, а ламинат только предстоит выбрать.
                            Действуйте по одному из предложенных сценариев, чтобы создать органичную
                            обстановку. </p>

                        <p><strong>Контраст. </strong>Сочетайте оттенки, которые кардинально отличаются друг от
                            друга. Темная дверь и светлый пол — смелое решение, которое зрительно увеличит
                            комнату. Соблюдайте цветовую температуру: холодный оттенок к холодному, теплый — к
                            теплому. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/9.jpg"/>
                        </div>

                        <p><strong>Сочетание. </strong>Ламинат, который на один-два тона светлее, чем дверь —
                            классический вариант. Он привлекает дизайнеров уже много лет. Чтобы добиться полной
                            гармонии, попробуйте подобрать дверную фурнитуру в цвет пола. Это сложно, но
                            результат вам понравится.<br/></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/10.jpg"/>
                        </div>

                        <p><strong>Совпадение. </strong>Самый простой способ сочетать дверь и ламинат — добиться
                            их совпадения. Гамма, тон, фактура должны быть одинаковыми. Узнайте, из какой породы
                            дерева изготовлена дверь (или — какую она имитирует). Выбирайте ламинат из такой же
                            древесины, и смотрите, чтобы рекомендации по соблюдению общей цветовой температуры
                            выполнялись.<br/></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/11.jpg"/>
                        </div>

                        <h2>Как подобрать плинтус к ламинату?</h2>

                        <p>Плинтус — связующее звено между дверью и полом. Если он выбран правильно, дверное
                            полотно интегрируется в интерьер, и он выглядит завершенным. Есть несколько способов
                            подобрать этот аксессуар: </p>

                        <ul>

                            <li>сочетать его с цветом двери. Это лучший вариант, если двери контрастного цвета,
                                и нужно ввести их в интерьер. Особенно это важно, когда пол темный — так
                                создается ощущение воздушности;
                            </li>

                            <li>сочетать с ламинатом. Такое решение создаст эффект монолитного пола. Чтобы
                                сделать интерьер более интересным, используйте плинтус на тон темнее или
                                светлее. Это внесет разнообразие в обстановку.
                            </li>

                        </ul>

                        <p>Универсальный вариант — установить белый плинтус. Здесь важно учитывать контрастность
                            и заранее представлять сочетание цветов. Если видите, что базовых оттенков больше
                            трех, не экспериментируйте. Выбирайте один из двух способов, предложенных выше. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/12.png"/>
                        </div>

                        <h2>Как подобрать ламинат по стилю?</h2>

                        <p>Если оформляете квартиру в определенном стиле, нужно знать, как подобрать цвет
                            ламината к интерьеру. Неправильное решение испортит общее впечатление. Рассмотрим
                            основные стили. </p>

                        <p><strong>Скандинавский. </strong>Лаконичный и современный. Для него характерны
                            легкость и большое количество света — естественного или искусственного. Добавить
                            уюта помогают яркие текстильные элементы. Выбирайте светлый ламинат, дающий ощущение
                            свежести. Подойдут все разновидности белого — известняк, арктический, отбеленный,
                            блонд и платина. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/13.jpg"/>
                        </div>

                        <p><strong>Современный. </strong>Мягкие линии, хлопковые материалы, природные текстуры.
                            Пол должен соответствовать. Подойдут все цвета, имитирующие натуральную древесину.
                            Кедр, бук, айвори и секвойя — то, что нужно. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/14.jpg"/>
                        </div>

                        <p><strong>Лофт. </strong>Если хотя бы одна стена напоминает кирпичную кладку, значит,
                            интерьер оформлен именно в этом стиле. Он предполагает наличие металлических
                            аксессуаров, отсутствие лишних деталей, приглушенные цвета. Подойдет серый ламинат и
                            все его модификации — кварц, гранит, серебро, беленый дуб, камень.<br/></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/15.jpg"/>
                        </div>

                        <p><strong>Классический. </strong>В основе — сочетание коричневых оттенков разной
                            насыщенности с благородной древесиной. Традиционная английская мебель, плавные
                            линии, консервативные очертания. Ищите соответствующий ламинат теплого коричневого
                            оттенка — каштан, мед, насыщенный дуб, золотистый мускат.<br/></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/16.jpg"/>
                        </div>

                        <p><strong>Хай-тек. </strong>Сочетание стекла, пластика и металла напоминает нам, что мы
                            живем в век высоких технологий. Такой стиль демонстрирует статус хозяина.
                            Встречается в респектабельных офисах и домашних интерьерах, над созданием которых
                            поработал дизайнер. Пусть ламинат в помещении хай-тек будет темным. Подойдут и
                            сероватые оттенки, и почти черные. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/laminat/17.jpg"/>
                        </div>

                        <div style={{textAlign: 'center', marginTop: '40px'}}>
                            <LinkButton text={'Вернуться на сайт'} path={modal ? "/#articles" : ""}/>
                        </div>
                    </div>
                </div>
            )}
        </ModalRoutingContext.Consumer>
    );
};

export default Laminat
